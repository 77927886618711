import React, { Component } from 'react'

// import SelectionPage from "../selection/selection-page"
import LoadingPage from '../loading/loading-page'
import NotAuthorized from '../loading/NotAuthorized'
// import LobbyArea from '../lobby/lobby-area';
import authFirebaseApp from '../functions/features/auth-firebase-app';
import loginFirebaseApp from '../functions/features/login-firebase-app';

const expoName = 'kala-launch'

class RootComponent extends Component {

    constructor() {
        super();
        this.state = {
            isValidUser: false,
            isLoading: true,
            unAuthorized: false,
            isPlayerSelected: localStorage.getItem('playerNum') ? true : false
        };

    }

    componentDidMount() {
        let userParamId = /[^/]*$/.exec(window.location.href)[0];

        if (userParamId) {
            console.warn('user email is ::',userParamId)
            let userPromise = new Promise((resolve, reject) => {
                loginFirebaseApp.firestore().collection('Users')
                    .where('uid', '==', userParamId)
                    .where('expo', '==', expoName)
                    .get().then(snapshots => {
                        console.warn('snapshots is ::',snapshots)
                        if (snapshots.size > 0) {
                            snapshots.forEach(doc => {
                                let myDoc = doc.data();
                                myDoc.id = doc.id;
                                resolve(myDoc);
                            })
                        }
                        else {
                            resolve('Not Found');
                            console.warn('not found')
                            // this.setState({
                            //     isValidUser: false,
                            //     isLoading: false,
                            //     unAuthorized: true
                            // })
                        }
                    }).catch(error => {
                        reject(error);
                    })
            });

            userPromise.then(result => {

                if (result !== 'Not Found') {
                    console.warn("User detail", result);

                    localStorage.setItem('uid', result.uid);
                    localStorage.setItem('userId', result.id);
                    localStorage.setItem('name', result.name);
                    localStorage.setItem('country', result.country);
                    localStorage.setItem('email', result.email);
                    localStorage.setItem('mobile', result.mobileNumber);
                    localStorage.setItem('designation', result.designation);
                    localStorage.setItem('hospital', result.hospital);
                    localStorage.setItem('roomName', 'webinar');
                    if (result.userRole) {
                        localStorage.setItem('userRole', result.userRole);
                        if (result.userRole === 'moderator') {
                            window.location.href = '/moderator-webinar'
                        } else if (result.userRole === 'speaker') {
                            window.location.href = '/speaker-webinar'
                        }
                    } else {
                        localStorage.setItem('userRole', 'user');

                        window.location.href = '/exterior'
                    }
                    this.setState({
                        //set visibility of pages
                        isValidUser: true,
                        isLoading: false,
                        unAuthorized: false
                    })
                } else {
                    this.setState({
                        isValidUser: false,
                        isLoading: false,
                        unAuthorized: true
                    })
                }
            }).catch(error => {
                console.error('User login error', error);
                alert('User login error : ', error)
            })

        }
    }


    render() {
        return (
            <React.Fragment>
                {/* {(this.state.isValidUser === true) && (
                    <MainPage />
                )} */}

                {this.state.isLoading && (
                    <LoadingPage />
                )}

                { this.state.unAuthorized && (
                    <NotAuthorized />
                )}


            </React.Fragment>
        )
    }
}


export default RootComponent
