import React, { Component } from 'react'

import rotateMobile from '../images/rotate-mobile.png';
import Footer from '../footer/footer-page';
import Header from '../header/header';
import './sales-page.css';
import { updateJwtAndTenant } from './setup-jitsi';
import { updateLastSceneEndTime } from '../functions/helpers/analytics-function';
import kala from "../images/medexIcon/logo.png";


const salesBgImg = 'https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/events/kalaa-launch-sales-room-bg.png';
const roomName = "kala-launch-sales";

export default class SalesPage extends Component {


    componentDidMount() {
        updateLastSceneEndTime(roomName)
        document.getElementById('meet').style.display = 'block'
        updateJwtAndTenant('melzoKalaaLaunch')
    }

    render() {
        return (
            <div>
                <Header headerType="user" />
                <div className="kala-logo-1" id="kala-1">

                    <a href="https://kalaa.melzo.com" target="_blank" >
                        <img id="kalaLogo" className="logo-width" src={kala} alt="logo" style={{ cursor: "pointer" }} ></img>
                    </a>
                </div>
                <div className="kala-logo-2" id="kala-2" >

                    <a href="https://kalaa.melzo.com" target="_blank" >
                        <img id="kalaLogo" className="logo-width" src={kala} alt="logo" style={{ cursor: "pointer" }} ></img>
                    </a>
                </div>
                <img src={salesBgImg} alt="" className="sales2dImg" />
                <div className="overlaylandscape">

                    <img src={rotateMobile} alt="" className="rotateimg" />
                    <p className="rotatetext">Rotate your device to talk with our sales person</p>
                </div>
                <Footer />
            </div>
        )
    }
}
